<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Detail Farmasi</strong>
        </h5>
        <a-spin v-if="isLoading" />
        <a-form :form="form" @submit="handleSubmit" v-if="!isLoading">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Nama Farmasi"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Nama Farmasi"
              v-decorator="[
                'name',
                {
                  rules: [
                    { required: true, message: 'Nama farmasi harus diisi!' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nomor KTP">
            <a-input
              :disabled="submitting"
              placeholder="Nomor KTP"
              v-decorator="['idNumber', { rules: [{required: true, len: 16, message: 'Nomor KTP harus 16 digit!' }]}]"
            />
          </a-form-item> -->
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="E-mail"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan E-mail"
              v-decorator="[
                'email',
                { rules: [{ required: true, message: 'E-mail harus diisi!' }] },
              ]"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Alamat"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Alamat"
              v-decorator="[
                'address',
                {
                  rules: [
                    { required: true, message: 'Alamat farmasi harus diisi!' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kota"
          >
            <a-select
              :disabled="submitting"
              v-decorator="[
                'city',
                { rules: [{ required: true, message: 'Kota harus diisi!' }] },
              ]"
              placeholder="Kota"
            >
              <a-select-option
                v-for="city in cityList"
                :key="city.id"
                :value="city.name"
              >
                {{ city.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Sertifikat"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'certificate',
                {
                  valuePropName: 'certificate',
                },
              ]"
              name="upload"
              :action="action"
              list-type="picture"
              :file-list="fileList"
              @change="handleChange"
            >
              <a-button> <a-icon type="upload" /> Click to upload </a-button>
            </a-upload>
          </a-form-item>
          <button
            type="submit"
            class="btn btn-success px-5"
            :disabled="submitting"
          >
            Simpan Data
          </button>
          <button
            class="btn btn-danger px-5 m-1"
            :disabled="submitting"
            @click="
              (e) => {
                e.preventDefault()
                setModalVisible(true)
              }
            "
          >
            Hapus Data
          </button>
          <button
            class="btn btn-info px-5 my-1"
            :disabled="submitting"
            @click="handleChangePassword"
          >
            Change Password
          </button>
          <button
            class="btn btn-primary px-5 m-1"
            :disabled="submitting"
            @click="
              (e) => {
                e.preventDefault()
                setModalVisible2(true)
              }
            "
          >
            Reset Password
          </button>
          <button
            class="btn btn-secondary px-5"
            :disabled="submitting"
            @click="handleCancel"
          >
            Kembali
          </button>
        </a-form>
      </div>
    </div>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible"
      @ok="() => handleDelete()"
      @cancel="() => setModalVisible(false)"
    >
      Apakah Anda yakin akan menghapus data farmasi
      {{ form.getFieldValue('fullname') }}?
    </a-modal>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible2"
      @ok="() => handleResetPassword()"
      @cancel="() => setModalVisible2(false)"
    >
      Apakah Anda yakin akan mereset password farmasi
      {{ form.getFieldValue('name') }}? <br />
      email akan dikirimkan ke {{ form.getFieldValue('email') }}
    </a-modal>
  </div>
</template>
<script>
import router from '@/router'
import { getById, editById, deleteById } from '@/services/axios/api/farmasi'
import { getAll } from '@/services/axios/api/city'
import { resetPassword } from '@/services/axios/api/auth'
import { notification } from 'ant-design-vue'
import { BASE_URL } from '@/config'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      isLoading: true,
      submitting: false,
      action: `${BASE_URL}/upload/certificate`,
      // action: `${BASE_URL}/upload/certificate`,
      fileList: [],
      modalVisible: false,
      modalVisible2: false,
    }
  },
  created() {
    this.getDoctorById()
    this.getCityList()
  },
  methods: {
    async getDoctorById() {
      // init form field
      this.form.getFieldDecorator('name', { initialValue: '' })
      this.form.getFieldDecorator('email', { initialValue: '' })
      this.form.getFieldDecorator('address', { initialValue: '' })
      this.form.getFieldDecorator('city', { initialValue: '' })
      this.form.getFieldDecorator('certificate', { initialValue: '' })
      // this.form.getFieldDecorator('idNumber', { initialValue: '' })

      // get data
      const res = await getById(this.$route.params.id)

      // set loading false
      this.isLoading = false

      if (res) {
        // set form data
        this.form.setFieldsValue({ name: res.name })
        this.form.setFieldsValue({ email: res.email })
        this.form.setFieldsValue({ address: res.address })
        this.form.setFieldsValue({ city: res.city })
        this.form.setFieldsValue({
          certificate: { file: { response: { data: res.certificate } } },
        })
        // this.form.setFieldsValue({ idNumber: res.idNumber })
        if (res.certificate) {
          const fileData = {
            uid: '-1',
            name: res.certificate.split('/')[
              res.certificate.split('/').length - 1
            ],
            status: 'done',
            url: `${BASE_URL}${res.certificate}`,
            // url: `${BASE_URL}${res.certificate}`,
          }
          this.fileList = [fileData]
        }
      }
    },
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            if (values.certificate && this.fileList.length) {
              values.certificate = values.certificate.file.response.data
            } else {
              values.certificate = ''
            }
            console.log(values)
            const res = await editById(this.$route.params.id, values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil disimpan',
                duration: 5,
              })
              router.push('/farmasi')
            }
            this.submitting = false
          } catch (e) {
            console.log(e)
            this.submitting = false
          }
        }
      })
    },
    handleChangePassword() {
      this.$router.push(
        `/farmasi/details/${this.$route.params.id}/change-password`,
      )
    },
    handleChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList
    },
    async handleDelete() {
      const res = await deleteById(this.$route.params.id)
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Data berhasil dihapus',
          duration: 5,
        })
        router.push('/farmasi')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Data gagal dihapus',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/farmasi')
    },
    async handleResetPassword() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await resetPassword(values, 'pharmacy')
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description:
                  'Password berhasil direset, silakan cek email terdaftar',
                duration: 5,
              })
            } else {
              notification.warning({
                message: 'Gagal',
                description: 'Password gagal direset',
                duration: 5,
              })
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
          this.modalVisible2 = false
        }
      })
    },
    setModalVisible(modalVisible) {
      this.modalVisible = modalVisible
    },
    setModalVisible2(modalVisible2) {
      this.modalVisible2 = modalVisible2
    },
  },
}
</script>
